import React, { useState } from "react"
import Progress from "../Progress"
import { AssignmentWithStatusAndProgressionStartPage } from "../../modules/assignments/types"
import { calculatedStatusTag } from "../../pages/Assignments/common/helpers"
import { Stickers, Sticker } from "../../modules/classroom/types"
import { StyledStatusTag } from "../../pages/Assignments/StyledAssignments"
import {
  StyledAssignmentIcon,
  StyledListItem,
  StyledListItemContent,
  StyledPrimaryText,
  StyledPrimaryTextContainer,
  StyledStatusWrapper
} from "./StyledAssignmentAndTestListItem"
import { StyledListItemIcon } from "../NavigationListItem/StyledNavigationListItem"
import {
  FormativeProgressionArr,
  FormativeTest
} from "../../modules/formative/types"
import FormativeStatusTag from "../../pages/FormativeQuestions/FormativeStatusTag"
import { ProgressForBar } from "../FormativeQuestionsTable/types"
import {
  getLatestSession,
  getProgressForBar
} from "../../pages/FormativeQuestions/helpers"
import { StyledTestIcon } from "../StudentHistoryList/StyledHistoryList"
import { StyledSticker } from "../AssignmentsListItem/StyledAssignmentsListItem"
import { selectClassroomId } from "../../modules/classroom/selectors"
import * as routerActions from "../../modules/router/actions"
import { useDispatch, useSelector } from "react-redux"
import { ListItemButton } from "@mui/material"
import Dialog from "../Dialog"
import ExercisesProgressDialog from "../ExercisesProgressDialog"
import { MembersWithOnlineStatus } from "../../pages/Exercises/store/types"
import { Member } from "../../modules/members/types"
import { selectStudentsWithProduct } from "../../modules/members/selectors"


type Props = {
  assignment?: AssignmentWithStatusAndProgressionStartPage | null
  test?: FormativeTest | null
  progress: FormativeProgressionArr
  stickers: Stickers
}
const NOOP = () => {}

function AssignmentAndTestListItem({
  assignment,
  test,
  progress,
  stickers
}: Props) {
  const dispatch = useDispatch()
  const classroomId = useSelector(selectClassroomId)
  const students = useSelector(selectStudentsWithProduct)
  const latestSession = test && getLatestSession(test.sessions)
  const [progressForTest, setProgressForTest] =
    React.useState<ProgressForBar | null>(null)

  const [progressDialogData, setProgressDialogData] = useState<{
    done: Member[]
    notStarted: Member[]
    inProgress: Member[]
    title: string
  } | null>(null)

  const inProgress = assignment ? assignment.progress.inProgress.length : 0
  const done = assignment ? assignment.progress.done.length : 0
  const notStarted = assignment ? assignment.progress.notStarted.length : 0
  const approved = assignment ? assignment.progress.approved.length : 0

  React.useEffect(() => {
    if (test) {
      const session = getLatestSession(test.sessions)
      if (progress && session) {
        const progressForBar = getProgressForBar(session, progress)
        setProgressForTest(progressForBar)
      }
    }
  }, [progress, test])

  const getCurrentSticker = (id: Sticker["id"]) => {
    const currentSticker = stickers.filter(
      (sticker: Sticker) => sticker.id === id
    )
    return currentSticker.length > 0 ? currentSticker[0] : stickers[0]
  }

  const goToTestResult = (testId: number) => {
    dispatch(
      routerActions.gotoRoute(routerActions.ROUTE_FORMATIVA_FRAGOR_INFO, {
        classroomId,
        testId
      })
    )
  }

  const handleClick = () => {
    if (assignment) {
      return assignment.onAssignmentClick(assignment.id)
    }
    if (test) {
      return goToTestResult(test.id)
    }
  }

  const renderIconForAssignment = () => {
    if (assignment && assignment.stickerId) {
      const sticker = getCurrentSticker(assignment.stickerId)
      if (sticker) {
        return <StyledSticker alt={sticker.name} src={sticker.path} />
      }
    }
    return <StyledAssignmentIcon />
  }

  const renderStatusForAssignment = () => (
    <>
      {assignment && (
        <StyledStatusTag status={assignment.calculatedStatus}>
          {calculatedStatusTag(
            assignment.calculatedStatus,
            assignment.startDate,
            assignment.endDate
          )}
        </StyledStatusTag>
      )}
    </>
  )

  const renderStatusForTest = () => (
    <>
      {test && (
        <FormativeStatusTag
          status={test.status}
          numberOfSessions={test.sessions.length}
          endsAt={(latestSession && latestSession.endedAt) || ""}
          onEndedCounter={NOOP}
        />
      )}
    </>
  )

  const renderProgressForAssignment = () => (
    <>
      {assignment && (
        <Progress
          onOpenProgressDialog={() => assignment.onProgressClick(assignment)}
          done={done + approved}
          type="assignments"
          inProgress={inProgress}
          notStarted={notStarted}
        />
      )}
    </>
  )

  const onProgressDialogClick =  (
    progressToShow: ProgressForBar & { [key: string]: number[] },
    title: string
  ) => {
    const dialogData: any = {
      done: [],
      inProgress: [],
      notStarted: [],
      title
    }
    const updatedDialogData = Object.keys(progressToShow).reduce((acc, key) => {
      progressToShow[key].forEach(memberId => {
        const progressStudent = students.find(
          student => student.studliId === memberId
        );
        if (progressStudent) {
          acc[key].push(progressStudent);
        }
      });
      return acc;
    }, dialogData);
  
    setProgressDialogData(updatedDialogData);
  }

  const renderProgressForTest = () => (
    <>
      {test && progressForTest && (
        <Progress
          onOpenProgressDialog={() =>
            onProgressDialogClick(progressForTest, test.title)}
          done={progressForTest.done.length}
          type="formativeQuestions"
          inProgress={progressForTest.inProgress.length}
          notStarted={progressForTest.notStarted.length}
        />
      )}
    </>
  )

  return (
    <>
      <StyledListItem divider onClick={handleClick} disablePadding>
        <ListItemButton>
          <StyledListItemIcon>
            {assignment && renderIconForAssignment()}
            {test && <StyledTestIcon />}
          </StyledListItemIcon>
          <StyledListItemContent>
            <StyledPrimaryTextContainer>
              <StyledPrimaryText>
                {assignment && assignment.title}
                {test && test.title}
              </StyledPrimaryText>
            </StyledPrimaryTextContainer>
            <StyledStatusWrapper>
              {assignment && renderStatusForAssignment()}
              {test && renderStatusForTest()}
            </StyledStatusWrapper>
            {assignment && renderProgressForAssignment()}
            {test && progressForTest && renderProgressForTest()}
          </StyledListItemContent>
        </ListItemButton>
      </StyledListItem>
      {progressDialogData !== null && (
        <Dialog
          onClose={() => setProgressDialogData(null)}
          open={progressDialogData !== null}
          maxWidth="sm"
          fullWidth
        >
          <ExercisesProgressDialog
            onClose={() => setProgressDialogData(null)}
            inProgress={
              progressDialogData.inProgress as MembersWithOnlineStatus
            }
            done={progressDialogData.done as MembersWithOnlineStatus}
            notStarted={
              progressDialogData.notStarted as MembersWithOnlineStatus
            }
            type="formativeQuestions"
            title={progressDialogData.title}
          />
        </Dialog>
      )}
    </>
  )
}

export default AssignmentAndTestListItem
