import { createAssignmentMessages } from "../../pages/CreateEditAssignment/common/errorMessages"
import { SnackbarData } from "./types"

type MemberMessageKeys =
  | "GET_ALL_MEMBERS_RESPONSE_WARNING"
  | "GET_ALL_MEMBERS_RESPONSE_FAILURE"
  | "ADD_MEMBER_STUDENT_RESPONSE"
  | "ADD_MEMBER_TEACHER_RESPONSE"
  | "ADD_MEMBER_STUDENT_RESPONSE_FAILURE"
  | "ADD_MEMBER_TEACHER_RESPONSE_FAILURE"
  | "REMOVE_STUDENT_RESPONSE"
  | "REMOVE_STUDENT_RESPONSE_FAILURE"
  | "REMOVE_TEACHER_RESPONSE"
  | "REMOVE_TEACHER_RESPONSE_FAILURE"
  | "CHANGE_MEMBER_PASSWORD_RESPONSE"
  | "CHANGE_MEMBER_PASSWORD_RESPONSE_FAILURE"
  | "CHANGE_CLASS_PASSWORD_RESPONSE_SUCCESS"
  | "CHANGE_CLASS_PASSWORD_RESPONSE_FAILURE"
  | "ADD_MEMBER_TO_GROUP_RESPONSE_FAILURE"
  | "ADD_MEMBER_TO_GROUP_RESPONSE_SUCCESS"

type ClassroomMessageKeys =
  | "CLASSROOM_INFO_RESPONSE_FAILURE"
  | "CLASSROOM_INFO_UPDATE_RESPONSE"
  | "CLASSROOM_INFO_UPDATE_RESPONSE_FAILURE"
  | "CHANGE_PRODUCT_RESPONSE_FAILURE"
  | "CHANGE_PRODUCT_RESPONSE"
  | "CLASSROOM_GROUPS_RESPONSE_FAILURE"

type UsersMessageKeys =
  | "SEARCH_EXISTING_USERS_STUDENT_RESPONSE"
  | "SEARCH_EXISTING_USERS_TEACHER_RESPONSE"
  | "SEARCH_EXISTING_USERS_RESPONSE_NO_MATCH"
  | "SEARCH_EXISTING_USERS_RESPONSE_NO_VALID_MATCH"
  | "SEARCH_EXISTING_USERS_RESPONSE_FAILURE"
  | "CREATE_USER_RESPONSE"
  | "CREATE_USER_RESPONSE_FAILURE"
  | "ACTIVATE_PRODUCT_KEYS_RESPONSE"
  | "AUTH_USER_INFO_RESPONSE_FAILURE"
  | "USER_CLASSROOMS_RESPONSE_FAILURE"
  | "GET_USER_CLASSROOMS_RESPONSE_NO_MATCH"
  | "GET_STUDENTS_FROM_CLASSROOMS_RESPONSE_FAILURE"
  | "AUTH_SSO_TOKEN_INVALIDATED"
  | "COPY_INVITE_STUDENTS_TO_CLASSROOM_CARD"

type AssignmentMessageKeys =
  | "CREATE_ASSIGNMENT_RESPONSE_FAILURE"
  | "CREATE_ASSIGNMENT_RESPONSE_SUCCESS"
  | "IMPORT_ASSIGNMENT_RESPONSE_FAILURE"
  | "IMPORT_ASSIGNMENT_RESPONSE_SUCCESS"
  | "EDIT_ASSIGNMENT_RESPONSE_FAILURE"
  | "EDIT_ASSIGNMENT_RESPONSE_SUCCESS"
  | "REMOVE_ASSIGNMENT_RESPONSE_FAILURE"
  | "REMOVE_ASSIGNMENT_RESPONSE_SUCCESS"
  | "ASSIGNMENT_APPROVED_RESPONSE"
  | "ASSIGNMENT_REJECTED_RESPONSE"
  | "ASSIGNMENT_NOT_FOUND"
  | "FAILED_TO_STORE_PREVIEW"
  | "FAILED_TO_STORE_PREVIEW_DUE_NO_ACTIVE_PRODUCT"
  | "ARCHIVE_ASSIGNMENT_RESPONSE_FAILURE"
  | "ARCHIVE_ASSIGNMENT_RESPONSE_SUCCESS"
  | "ACTIVATE_ASSIGNMENT_RESPONSE_FAILURE"
  | "ACTIVATE_ASSIGNMENT_RESPONSE_SUCCESS"
  | "PUBLISH_ASSIGNMENT_RESPONSE_FAILURE"
  | "PUBLISH_ASSIGNMENT_RESPONSE_SUCCESS"
  | "FINISH_ASSIGNMENT_RESPONSE_FAILURE"
  | "FINISH_ASSIGNMENT_RESPONSE_SUCCESS"

type FormativeTestMessageKeys =
  | "CREATE_TEST_RESPONSE_FAILURE"
  | "CREATE_TEST_RESPONSE_SUCCESS"
  | "CREATE_TEST_MISSING_TITLE"
  | "START_TEST_RESPONSE_SUCCESS"
  | "START_TEST_RESPONSE_FAILURE"
  | "STOP_TEST_RESPONSE_SUCCESS"
  | "STOP_TEST_RESPONSE_FAILURE"
  | "SAVE_TEST_RESPONSE_SUCCESS"
  | "SAVE_TEST_RESPONSE_FAILURE"
  | "EDIT_TEST_RESPONSE_SUCCESS"
  | "EDIT_TEST_RESPONSE_FAILURE"
  | "REMOVE_TEST_RESPONSE_SUCCESS"
  | "REMOVE_TEST_RESPONSE_FAILURE"
  | "ARCHIVE_TEST_RESPONSE_FAILURE"
  | "ARCHIVE_TEST_RESPONSE_SUCCESS"
  | "ACTIVATE_TEST_RESPONSE_FAILURE"
  | "ACTIVATE_TEST_RESPONSE_SUCCESS"
  | "GET_TEST_RESPONSE_FAILURE"
  | "IMPORT_TEST_RESPONSE_SUCCESS"
  | "IMPORT_TEST_RESPONSE_FAILURE"

type GoalMessages =
  | "SET_START_GOAL_RESPONSE_FAILURE"
  | "SET_START_GOAL_RESPONSE_SUCCESS"

type MessageType = Record<
  | MemberMessageKeys
  | ClassroomMessageKeys
  | UsersMessageKeys
  | AssignmentMessageKeys
  | FormativeTestMessageKeys
  | GoalMessages,
  SnackbarData
>

export const snackbarMessages: MessageType = {
  //Members
  GET_ALL_MEMBERS_RESPONSE_WARNING: {
    severity: "warning",
    message: "Klassrummet har inga elever än."
  },
  GET_ALL_MEMBERS_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte hämta klassrummets elever."
  },
  ADD_MEMBER_TO_GROUP_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte lägga till elever i grupper."
  },
  ADD_MEMBER_TO_GROUP_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Elever lades till i grupp."
  },

  ADD_MEMBER_STUDENT_RESPONSE: {
    severity: "success",
    message: "Elev(er) lades till i klassrummet."
  },
  ADD_MEMBER_TEACHER_RESPONSE: {
    severity: "success",
    message: "Lärare lades till i klassrummet."
  },
  ADD_MEMBER_STUDENT_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte lägga till elev(er) till klassrummet."
  },
  ADD_MEMBER_TEACHER_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte lägga till lärare till klassrummet."
  },

  REMOVE_STUDENT_RESPONSE: {
    severity: "success",
    message: "Elev borttagen från klassrummet."
  },
  REMOVE_TEACHER_RESPONSE: {
    severity: "success",
    message: "Lärare borttagen från klassrummet."
  },
  REMOVE_STUDENT_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte ta bort elev från klassrummet."
  },
  REMOVE_TEACHER_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte ta bort lärare från klassrummet."
  },

  CHANGE_MEMBER_PASSWORD_RESPONSE: {
    severity: "success",
    message: "Lösenordet ändrat."
  },
  CHANGE_MEMBER_PASSWORD_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte ändra lösenordet."
  },
  CHANGE_CLASS_PASSWORD_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Lösenordet ändrat för eleverna."
  },
  CHANGE_CLASS_PASSWORD_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte ändra lösenordet för eleverna."
  },

  // Classroom
  CLASSROOM_INFO_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte hämta information om klassrummet."
  },
  CLASSROOM_INFO_UPDATE_RESPONSE: {
    severity: "success",
    message: "Ny information om klassrummet sparades."
  },
  CLASSROOM_INFO_UPDATE_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte uppdatera information om klassrummet."
  },
  CLASSROOM_GROUPS_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte hämta grupper."
  },
  // Users
  SEARCH_EXISTING_USERS_STUDENT_RESPONSE: {
    severity: "info",
    message: "Elev hittad."
  },
  SEARCH_EXISTING_USERS_TEACHER_RESPONSE: {
    severity: "info",
    message: "Lärare hittad."
  },
  SEARCH_EXISTING_USERS_RESPONSE_NO_MATCH: {
    severity: "warning",
    message: "Hittade inget som matchade."
  },
  SEARCH_EXISTING_USERS_RESPONSE_NO_VALID_MATCH: {
    severity: "warning",
    message: "Hittar ingen behöring användare"
  },
  SEARCH_EXISTING_USERS_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte söka, något gick fel."
  },

  CREATE_USER_RESPONSE: {
    severity: "success",
    message: "Konto skapat och elev lades till i klassrummet."
  },
  CREATE_USER_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte lägga till elev i klassrummet."
  },
  AUTH_USER_INFO_RESPONSE_FAILURE: {
    severity: "warning",
    message: "Kunde inte hämta information om inloggad användare"
  },

  ACTIVATE_PRODUCT_KEYS_RESPONSE: {
    severity: "success",
    message: "Produkt aktiverad."
  },
  CHANGE_PRODUCT_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte byta produkt för klassrummet."
  },
  CHANGE_PRODUCT_RESPONSE: {
    severity: "info",
    message: "Din aktiva produkt är nu {product}."
  },

  USER_CLASSROOMS_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte hämta klassrum."
  },
  GET_USER_CLASSROOMS_RESPONSE_NO_MATCH: {
    severity: "warning",
    message: "Hittade inget som matchade"
  },
  GET_STUDENTS_FROM_CLASSROOMS_RESPONSE_FAILURE: {
    severity: "error",
    message: "Kunde inte hämta elever från klassrummen."
  },

  // ASSIGNMENTS

  CREATE_ASSIGNMENT_RESPONSE_FAILURE: {
    severity: "error",
    message: "Uppdraget kunde inte skapas, försök igen."
  },
  IMPORT_ASSIGNMENT_RESPONSE_FAILURE: {
    severity: "error",
    message: "Uppdraget kunde inte importeras, försök igen."
  },
  IMPORT_ASSIGNMENT_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Uppdraget har importerats."
  },
  CREATE_ASSIGNMENT_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Uppdraget har skapats."
  },
  EDIT_ASSIGNMENT_RESPONSE_FAILURE: {
    severity: "error",
    message: "Uppdraget kunde inte sparas, försök igen."
  },
  EDIT_ASSIGNMENT_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Uppdraget har sparats."
  },
  ARCHIVE_ASSIGNMENT_RESPONSE_FAILURE: {
    severity: "error",
    message: "Uppdraget kunde inte arkiveras, försök igen."
  },
  ARCHIVE_ASSIGNMENT_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Uppdraget har arkiverats."
  },
  ACTIVATE_ASSIGNMENT_RESPONSE_FAILURE: {
    severity: "error",
    message: "Uppdraget kunde inte aktiveras, försök igen."
  },
  ACTIVATE_ASSIGNMENT_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Uppdraget har aktiverats."
  },
  PUBLISH_ASSIGNMENT_RESPONSE_FAILURE: {
    severity: "error",
    message: "Uppdraget kunde inte publiceras, försök igen."
  },
  PUBLISH_ASSIGNMENT_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Uppdraget har publicerats."
  },
  FINISH_ASSIGNMENT_RESPONSE_FAILURE: {
    severity: "error",
    message: "Uppdraget kunde inte avslutas, försök igen."
  },
  FINISH_ASSIGNMENT_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Uppdraget har avslutats."
  },
  REMOVE_ASSIGNMENT_RESPONSE_FAILURE: {
    severity: "error",
    message: "Uppdraget kunde inte tas bort, försök igen."
  },
  REMOVE_ASSIGNMENT_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Uppdraget har tagits bort."
  },
  ASSIGNMENT_APPROVED_RESPONSE: {
    severity: "success",
    message: "Uppdraget har godkänts."
  },
  ASSIGNMENT_REJECTED_RESPONSE: {
    severity: "success",
    message: "Uppdraget har skickats tillbaka."
  },
  ASSIGNMENT_NOT_FOUND: {
    severity: "warning",
    message: "Vi kunde tyvärr inte hitta uppdraget du letade efter."
  },
  FAILED_TO_STORE_PREVIEW: {
    severity: "error",
    message: "Vi kunde tyvärr inte öppna en förhandsgranskning av uppdraget."
  },
  FAILED_TO_STORE_PREVIEW_DUE_NO_ACTIVE_PRODUCT: {
    severity: "error",
    message:
      "Du måste ha tillgång till den aktuella elevboken för att kunna förhandsgranska uppdraget."
  },
  CREATE_TEST_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Ditt test har sparats."
  },
  CREATE_TEST_RESPONSE_FAILURE: {
    severity: "error",
    message: "Vi kunde tyvärr inte skapa ditt test."
  },
  CREATE_TEST_MISSING_TITLE: {
    severity: "error",
    message: "Testet behöver ha en titel."
  },
  START_TEST_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Testet har startats."
  },
  START_TEST_RESPONSE_FAILURE: {
    severity: "error",
    message: "Testet kunde ej startas."
  },
  STOP_TEST_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Testet har avslutats."
  },
  STOP_TEST_RESPONSE_FAILURE: {
    severity: "error",
    message: "Testet kunde ej avslutats."
  },
  GET_TEST_RESPONSE_FAILURE: {
    severity: "warning",
    message: "Vi kunde tyvärr inte hitta testet du letade efter."
  },
  IMPORT_TEST_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Testet har importerats."
  },
  IMPORT_TEST_RESPONSE_FAILURE: {
    severity: "error",
    message: "Testet kunde ej importeras."
  },
  SAVE_TEST_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Testet har sparats."
  },
  SAVE_TEST_RESPONSE_FAILURE: {
    severity: "error",
    message: "Testet kunde ej sparas."
  },
  EDIT_TEST_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Testet har sparats."
  },
  EDIT_TEST_RESPONSE_FAILURE: {
    severity: "error",
    message: "Testet kunde ej sparas, försök igen."
  },
  REMOVE_TEST_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Testet har tagits bort."
  },
  ARCHIVE_TEST_RESPONSE_FAILURE: {
    severity: "error",
    message: "Testet kunde ej arkiveras."
  },
  ARCHIVE_TEST_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Testet har arkiverats."
  },
  ACTIVATE_TEST_RESPONSE_FAILURE: {
    severity: "error",
    message: "Testet kunde ej aktiveras."
  },
  ACTIVATE_TEST_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Testet har aktiverats."
  },
  REMOVE_TEST_RESPONSE_FAILURE: {
    severity: "error",
    message: "Testet kunde ej tas bort."
  },
  AUTH_SSO_TOKEN_INVALIDATED: {
    severity: "warning",
    message: "Du har blivit utloggad."
  },
  COPY_INVITE_STUDENTS_TO_CLASSROOM_CARD: {
    severity: "info",
    message: "Texten är kopierad."
  },
  SET_START_GOAL_RESPONSE_SUCCESS: {
    severity: "success",
    message: "Startmålet har ändrats."
  },
  SET_START_GOAL_RESPONSE_FAILURE: {
    severity: "error",
    message: "Startmålet kunde ej ändras."
  },
  ...createAssignmentMessages
}
