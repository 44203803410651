import * as actions from "./actions"
import { State, CreateEditTestsActions } from "./types"

const initialState: State = {
  isCreateFormDirty: false
}

const reducer = (
  state = initialState,
  action: CreateEditTestsActions
): State => {
  if (action.type === actions.UI.SET_CREATE_FORM_DIRTY) {
    const { isDirty } = action.payload;
    return isDirty !== undefined
      ? { ...state, isCreateFormDirty: isDirty }
      : { ...state };
  }

  return state;
};

export default reducer;

