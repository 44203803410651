/* eslint-disable import/no-cycle */
import {
  createStore,
  compose,
  applyMiddleware,
  combineReducers,
  Store
} from "redux"
import { connectRoutes, Bag, Meta, StateGetter } from "redux-first-router"
import ReactGA from "react-ga"

import routesMap from "../../routes"
import appShell from "../../pages/AppShell/store/reducer"
import classroom from "../classroom/reducer"
import communication from "../communication/reducer"
import application from "../application/reducer"
import socket from "../socket/reducer"
import products from "../products/reducer"
import members from "../members/reducer"
import books from "../book/reducer"
import membersPage from "../../pages/Members/store/reducer"
import users from "../users/reducer"
import auth from "../auth/reducer"
import goals from "../goals/reducer"
import assignments from "../assignments/reducer"
import exercises from "../exercises/reducer"
import exercisesProgress from "../exercisesProgress/reducer"
import assignmentsProgress from "../assignmentsProgress/reducer"
import goalsPage from "../../pages/Goals/store/reducer"
import goalsProgress from "../goalsProgress/reducer"
import exercisesPages from "../../pages/Exercises/store/reducer"
import createEditAssignment from "../../pages/CreateEditAssignment/store/reducer"
import createEditFormativeTest from "../../pages/CreateFormativeQuestion/store/reducer"
import assignmentStudentCard from "../../pages/AssignmentStudentCard/store/reducer"
import notifications from "../notifications/reducer"
import router from "../router/reducer"
import formative from "../formative/reducer"
import { setApplicationMount } from "../application/actions"
import { SagasService } from "../../shared/services/sagas"
import createSagaMiddleware from "redux-saga"
import logMiddleware from "../application/logMiddleware"
import { Dispatch } from "react"
import { changeActiveProduct } from "../products/actions"
import {
  setCloseModal,
  setIsModalOpen
} from "../../components/ConfirmBeforeLeaveDialog/store/actions"
import { store as reduxStore } from "../.."
import confirmBeforeLeaveModal from "../../components/ConfirmBeforeLeaveDialog/store/reducer"

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: <R>(a: R) => R
    store: Store
  }
}

const sagaMiddleware = createSagaMiddleware()

function getComposeEnhancers(): <R>(a: R) => R {
  if (
    process.env.REACT_APP_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ // eslint-disable-line
  ) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ // eslint-disable-line
  }

  return compose
}

const options = {
  initialDispatch: false, // do not initiate route until we say so
  notFoundPath: "/:classroomId/start",
  displayConfirmLeave: (message: string, callback: Function) => {
    const canLeave = (can: boolean) => {
      reduxStore.dispatch(setCloseModal())
      return callback(can)
    }
    reduxStore.dispatch(setIsModalOpen(message, canLeave))
    return false
  },
  onAfterChange: (
    dispatch: Dispatch<any>,
    getState: StateGetter<any>,
    bag: Bag
  ) => {
    window.scrollTo(0, 0)
    const meta = bag.action.meta as Meta
    if (meta && meta.location && meta.location.current) {
      ReactGA.pageview(`/mittklassrum${meta.location.current.pathname}`)
    }
  }
}

const {
  reducer: routeReducer,
  middleware: routeMiddleware,
  enhancer: routeEnhancer
} = connectRoutes(routesMap, options)

const pages = combineReducers({
  appShell,
  goalsPage,
  membersPage,
  exercisesPages,
  createEditAssignment,
  createEditFormativeTest,
  assignmentStudentCard,
  confirmBeforeLeaveModal
})

export const rootReducer = combineReducers({
  classroom,
  communication,
  application,
  location: routeReducer,
  socket,
  products,
  members,
  users,
  auth,
  goals,
  exercises,
  goalsProgress,
  exercisesProgress,
  assignmentsProgress,
  assignments,
  notifications,
  books,
  router,
  formative,
  pages
})

export type RootState = ReturnType<typeof rootReducer>

const createReducer = (initialState = {}) => {
  const middlewares = applyMiddleware(
    s => next => async action => {
      if (action.type) {
        s.getState().application.latestAction = action.type
      }
      if (
        !action.payload ||
        Object.keys(routesMap).indexOf(action.type) === -1
      ) {
        next(action)

        return
      }

      if (!action.payload.productId) {
        next(action)

        return
      }

      let productId = action.payload.productId

      if (typeof productId === "string") {
        productId = Number(productId)
      }

      if (productId !== s.getState().products.activeProductId) {
        await next(changeActiveProduct(parseInt(action.payload.productId)))
      }

      next(action)
    },
    routeMiddleware,
    sagaMiddleware,
    logMiddleware
  )
  const enhancers = compose(routeEnhancer, middlewares)
  const composeEnhancers = getComposeEnhancers()

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(enhancers)
  )

  sagaMiddleware.run(SagasService.root())
  store.dispatch(setApplicationMount())

  return store
}

export default createReducer
