import * as React from "react"

import Dialog from "../Dialog/Dialog"
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemButton,
  List,
  Typography,
  Stack,
  Button,
  IconButton,
  ListItemAvatar,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectAllImportableAssignments } from "../../modules/assignments/selectors"
import {
  AssignmentStatuses,
  ImportableAssignmentClassroom
} from "../../modules/assignments/types"
import { StyledStatusTag } from "../../pages/Assignments/StyledAssignments"
import {
  calculatedStatusTag,
  calculateStatus
} from "../../pages/Assignments/common/helpers"
import { selectIsAdapt } from "../../modules/router/dataSelectors"
import { Moment } from "moment"
import { importAssignment } from "../../modules/assignments/actions"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
export interface Props {
  onClose: () => void
}

const ClassroomAssignmentImportDialog: React.FC<Props> = ({
  onClose
}): JSX.Element => {
  const classrooms = useSelector(selectAllImportableAssignments)
  const isAdapt = useSelector(selectIsAdapt)
  const dispatch = useDispatch()
  const [selectedClassroom, setSelectedClassroom] =
    React.useState<ImportableAssignmentClassroom | null>(null)
  const [selectedAssignments, setSelectedAssignments] = React.useState<
    number[]
  >([])
  const [checkedAssignments, setCheckedAssignments] = React.useState<number[]>(
    []
  )
  const [withUsers, setWithUsers] = React.useState(false)
  const [markedClassroomId, setMarkedClassroomId] = React.useState<number>()

  const getAssignmentsStatus = (
    status: AssignmentStatuses,
    startDate: Moment,
    endDate: Moment | null
  ) => {
    return calculateStatus(status, startDate, endDate, isAdapt)
  }

  const onImport = () => {
    dispatch(importAssignment(selectedAssignments, withUsers))
    onClose()
  }

  const onToggleAssignment = (id: number) => {
    if (checkedAssignments.includes(id)) {
      setCheckedAssignments(checkedAssignments.filter(a => a !== id))
    } else {
      setCheckedAssignments([...checkedAssignments, id])
    }
  }

  const handleSetWithUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWithUsers(!!+event.target.value)
  }

  const onGoBack = () => {
    setSelectedAssignments([])
    setCheckedAssignments([])
    if (selectedAssignments.length === 0) {
      setSelectedClassroom(null)
    }
  }

  const onCancel = () => {
    setMarkedClassroomId(undefined)
    onClose()
  }

  const handleMarkedClassroomChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMarkedClassroomId(Number(e.target.value))
  }

  const onChooseClassroomClick = () => {
    const classroom = classrooms.find(c => c.id === markedClassroomId)
    if (classroom) {
      setSelectedClassroom(classroom)
    }
  }

  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" spacing={1}>
          {selectedClassroom && (
            <IconButton onClick={onGoBack} aria-label="Gå tillbaka">
              <ArrowBackIcon />
            </IconButton>
          )}
          {selectedClassroom && selectedAssignments.length === 0 ? (
            <Stack direction="row" spacing={1} alignItems="center">
              <span>{selectedClassroom.title}</span>
            </Stack>
          ) : (
            "Importera uppdrag"
          )}
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        {!selectedClassroom && (
          <Stack spacing={1}>
            <Typography px={3}>
              Här kan du hämta uppdrag från klassrum du använt tidigare. Välj
              klassrum och därefter vilket/vilka uppdrag du vill hämta.
            </Typography>
            <List>
              <FormControl fullWidth>
                <RadioGroup
                  value={markedClassroomId}
                  onChange={handleMarkedClassroomChange}
                >
                  {classrooms.map((c, i) => (
                    <ListItem
                      sx={{ px: 3 }}
                      disablePadding
                      key={c.id}
                      divider={i < classrooms.length - 1}
                    >
                      <ListItemButton
                        sx={{ height: "4rem", cursor: "pointer", p: 0 }}
                      >
                        <FormControlLabel
                          sx={{ width: "100%" }}
                          value={c.id}
                          control={<Radio disableRipple />}
                          label={c.title}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </RadioGroup>
              </FormControl>
            </List>
          </Stack>
        )}
        {selectedClassroom && selectedAssignments.length === 0 && (
          <List>
            {selectedClassroom.assignments.map(
              ({ id, title, status, startDate, endDate }) => {
                const calcStatus = getAssignmentsStatus(
                  status,
                  startDate,
                  endDate
                )
                return (
                  <ListItem disablePadding divider key={id} button>
                    <ListItemButton
                      sx={{ height: "4rem" }}
                      onClick={() => onToggleAssignment(id)}
                    >
                      <ListItemAvatar>
                        <Checkbox
                          checked={checkedAssignments.includes(id)}
                          onClick={() => onToggleAssignment(id)}
                        />
                      </ListItemAvatar>
                      <Stack direction="row" spacing={2}>
                        <Typography>{title}</Typography>
                        <StyledStatusTag status={calcStatus}>
                          {calculatedStatusTag(calcStatus, startDate, endDate)}
                        </StyledStatusTag>
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                )
              }
            )}
          </List>
        )}
        {selectedClassroom && selectedAssignments.length > 0 && (
          <Stack direction="column" px={4} spacing={2}>
            <Typography>
              Om du bara vill kunna se innehållet eller använda uppdraget som en
              mall, välj Importera utan elever. Vill du kunna följa upp dina
              elevers resultat, välj Importera med elever.{" "}
            </Typography>
            <FormControl>
              <RadioGroup value={withUsers} onChange={handleSetWithUsers}>
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  checked={!withUsers}
                  label="Importera utan elever"
                />
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  checked={withUsers}
                  label="Importera med elever"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Avbryt</Button>
        {!selectedClassroom && (
          <Button
            disabled={!markedClassroomId}
            onClick={onChooseClassroomClick}
          >
            Välj klassrum
          </Button>
        )}
        {selectedClassroom && selectedAssignments.length > 0 && (
          <Button
            onClick={onImport}
            disabled={selectedAssignments.length === 0}
            color="primary"
          >
            Importera {selectedAssignments.length} uppdrag
          </Button>
        )}
        {selectedClassroom && selectedAssignments.length === 0 && (
          <Button
            onClick={() => setSelectedAssignments([...checkedAssignments])}
            disabled={checkedAssignments.length === 0}
            color="primary"
          >
            {checkedAssignments.length === 0
              ? "Välj"
              : `Välj ${checkedAssignments.length} uppdrag`}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ClassroomAssignmentImportDialog
