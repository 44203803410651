/**
 * LastActive shows the time passed since the student was last recorded as active.
 * (ie. client received NEW_ANSWER for this student)
 * The "date" received by this component will be that point in time.
 * As time goes by this component will periodically re-render itself to reflect this.
 * So, for example, "Nu" will become "1 min sedan", and then "2 min sedan" and so on.
 */

import React from "react"
import moment from "moment"
import "moment/locale/sv"
import { StyledLastActive } from "./StyledLastActive"

/**
 * Number of ms between calling forceUpdate().
 * Note that React will still only update the DOM if the markup changes.
 * Note that render() will get called on each update of the prop date (NEW_ANSWER)
 */
const _UPDATE_INTERVAL = 3000

type Props = {
  date: string
  shortFormat?: boolean
  children?: never
}

class LastActive extends React.PureComponent<Props> {
  state = {
    timeString: "",
    intervalId: -1
  }

  componentDidMount() {
    this.updateTimeString()
    const intervalId = setInterval(
      () => this.updateTimeString(),
      _UPDATE_INTERVAL
    )
    this.setState({ intervalId })
  }

  componentWillUnmount() {
    this.state.intervalId && clearInterval(this.state.intervalId)
  }

  updateTimeString = () => {
    const lastActive = LastActive.renderLastActive(
      this.props.date,
      this.props.shortFormat
    )
    this.setState({
      timeString: lastActive
    })
  }

  /**
   * Builds the correct string to show to represent the difference in time between lastActive and now.
   *
   * @param lastActive Time stamp received from server when member was last recorded as active
   */

  render() {
    const { timeString } = this.state

    return <StyledLastActive>{timeString}</StyledLastActive>
  }

  static renderLastActive(lastActive: string, shortFormat = false) {
    const lastActiveMoment = moment(lastActive, "YYYY-MM-DDTHH:mm:ssZ")

    const timePassedSinceLastActive = moment.duration(
      moment().diff(lastActiveMoment)
    )

    if (!lastActiveMoment.isValid()) {
      return "inte än"
    }
    if (timePassedSinceLastActive.asSeconds() <= 60) {
      return "nu"
    } else {
      return lastActiveMoment.fromNow(shortFormat)
    }
  }
}

export default LastActive
