import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Formik, FormikActions } from "formik"
import * as Yup from "yup"
import FormativeQuestionsForm from "../../components/FormativeQuestionsForm"
import {
  createFormativeTest,
  editFormativeTest,
  getTest,
  resetImportedTest
} from "../../modules/formative/actions"
import {
  CreateFormativeTest,
  EditFormativeTest,
  PartialFormativeTest
} from "../../modules/formative/types"
import TitleAndDescription from "../../components/TitleAndDescription"
import { setSnackbar } from "../../modules/application/actions"
import { snackbarMessages } from "../../containers/Snackbar/snackbarMessages"
import {
  selectCurrentRoute,
  selectCurrentTestId
} from "../../modules/router/selectors"
import {
  selectPendingImportFormativeTest,
  selectTestInRoute
} from "../../modules/formative/selectors"
import * as createEditTestsActions from "./store/actions"
import * as routerActions from "../../modules/router/actions"
import { getLatestSession } from "../FormativeQuestions/helpers"
import { selectClassroomId } from "../../modules/classroom/selectors"
import { toTextAndAudio } from "../../shared/tools/parse"
import { FormValues } from "./store/types"
import { selectAuthenticatedUser } from "../../modules/users/selectors"


export const CreateFormativeQuestion = () => {
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [importedFormativeTest, setImportedFormativeTest] =
    useState<PartialFormativeTest>()

  const editTest = useSelector(selectTestInRoute)
  const currentRoute = useSelector(selectCurrentRoute)
  const classroomId = useSelector(selectClassroomId)
  const testInRoute = useSelector(selectCurrentTestId)
  const pendingImport = useSelector(selectPendingImportFormativeTest)
  const user = useSelector(selectAuthenticatedUser)
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      testInRoute &&
      currentRoute === routerActions.ROUTE_FORMATIVA_FRAGOR_REDIGERA
    ) {
      dispatch(getTest({ id: testInRoute }))
    }
  }, [testInRoute, dispatch, currentRoute])

  useEffect(() => {
    dispatch(createEditTestsActions.setIsCreateFormDirty(false))  
  }, [dispatch])

  useEffect(() => {
    if (pendingImport) {
      setImportedFormativeTest(pendingImport)
      dispatch(resetImportedTest())
    }
    // eslint-disable-next-line
  }, [pendingImport, setImportedFormativeTest, resetImportedTest])

  const initialValues = {
    title: "",
    instruction: { text: "", audio: "" },
    endQuestion: { text: "", audio: "" },
    status: "draft",
    questions: [],
    members: [],
    isPublic: false
  } as FormValues

  const getInitialValues = () => {
    if (
      currentRoute === routerActions.ROUTE_FORMATIVA_FRAGOR_REDIGERA &&
      editTest
    ) {
      const latestSession = getLatestSession(editTest.sessions)
      return {
        title: editTest.title,
        instruction: toTextAndAudio(editTest.instruction),
        endQuestion: toTextAndAudio(editTest.endQuestion),
        status: editTest.status,
        questions: editTest.questions,
        members: latestSession ? latestSession.members : [],
        isPublic: editTest.isPublic
      } as FormValues
    }

    if (importedFormativeTest) {
      return {
        ...initialValues,
        ...importedFormativeTest,
        instruction: toTextAndAudio(
          importedFormativeTest.instruction as string
        ),
        endQuestion: toTextAndAudio(
          importedFormativeTest.endQuestion as string
        ),
        isPublic: false
      } as FormValues
    }

    return initialValues
  }

  const validationSchema = Yup.object({
    title: Yup.string().required("Testet måste ha en titel."),
    members: Yup.array().required("Testet måste ha elever."),
    instruction: Yup.string().notRequired(),
    questions: Yup.array().required("Testet måste ha innehåll.")
  })

  const setFormIsDirty = (isFormDirty: boolean) => {
    if (isDirty) {
      return
    }
    setIsDirty(isFormDirty)
    dispatch(createEditTestsActions.setIsCreateFormDirty(isFormDirty));  
  }

  const saveAsDraft = (values: FormValues) => {

    dispatch(createEditTestsActions.setIsCreateFormDirty(false));

    if (!values.title) {
      dispatch(setSnackbar(snackbarMessages.CREATE_TEST_MISSING_TITLE))
      return
    }
    let test = {
      ...values,
      questions: values.questions.map(question => question.id)
    }
    if (typeof values.instruction !== "string" && values.instruction) {
      test.instruction = JSON.stringify(values.instruction)
    }
    if (typeof values.endQuestion !== "string" && values.endQuestion) {
      test.endQuestion = JSON.stringify(values.endQuestion)
    }
    values.status = "draft"
    if (editTest) {
      dispatch(
        editFormativeTest({
          test: { ...test, id: editTest.id } as EditFormativeTest,
          echo: ""
        })
      )
      return
    }
    dispatch(createFormativeTest(test as CreateFormativeTest))
  }

  const goBack = () => {
    dispatch(
      routerActions.gotoRoute(routerActions.ROUTE_FORMATIVA_FRAGOR, {
        classroomId
      })
    )
  }

  const isCreator = useMemo(() => {
    if (!editTest) {
      return true
    }
    return editTest.createdBy === user?.studliId
  }, [editTest, user])

  const handleSubmit = (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {

    dispatch(createEditTestsActions.setIsCreateFormDirty(false));

    let test = {
      ...values,
      questions: values.questions.map(question => question.id)
    }

    test.status = "published"
    if (typeof values.instruction !== "string" && values.instruction) {
      test.instruction = JSON.stringify(values.instruction)
    }
    if (typeof values.endQuestion !== "string" && values.endQuestion) {
      test.endQuestion = JSON.stringify(values.endQuestion)
    }
    if (editTest) {
      dispatch(
        editFormativeTest({
          test: { ...test, id: editTest.id } as EditFormativeTest,
          echo: ""
        })
      )
      return
    }
    dispatch(createFormativeTest(test as CreateFormativeTest))
  }
  return (
    <>
      <TitleAndDescription
        title={editTest ? "Redigera test" : "Nytt test"}
        paragraphs={[]}
      />
      <Formik
        initialValues={getInitialValues()}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={true}
        enableReinitialize={!isDirty}
      >
        {formikProps => (
          <FormativeQuestionsForm
            goBack={goBack}
            test={editTest}
            saveAsDraft={saveAsDraft}
            setIsDirty={setFormIsDirty}
            isCreator={isCreator}
            {...formikProps}
          />
        )}
      </Formik>
    </>
  )
}

export default CreateFormativeQuestion
